import React from 'react';
import {Typography} from '@mui/material';

function HomePage() {
    return (
        <div>
            <Typography variant='h4' gutterBottom>
                well
            </Typography>
            <Typography variant='body'>
                it's a start
            </Typography>
        </div>
    )
}

export default HomePage;