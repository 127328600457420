import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, Typography, Container } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
// import MenuBookIcon from '@mui/icons-material/MenuBook';
// import CodeIcon from '@mui/icons-material/Code';
// import ContactMailIcon from '@mui/icons-material/ContactMail';
import './App.css';
import HomePage from './routes/HomePage.js';
// import ProjectsPage from './ProjectsPage';
// import BlogPage from './BlogPage';
// import ContactPage from './ContactPage';

function App() {
  return (
    <Router>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            andym.li
          </Typography>
          <Button color="inherit" component={Link} to="/" style={{textTransform: 'none'}}>
            <HomeIcon />  /home
          </Button>
          {/* <Button color="inherit" component={Link} to="/projects" style={{textTransform: 'none'}}>
            <CodeIcon />  /lore
          </Button> */}
          {/* <Button color="inherit" component={Link} to="/blog" style={{textTransform: 'none'}}>
            <MenuBookIcon />  /blog
          </Button> */}
          {/* <Button color="inherit" component={Link} to="/contact" style={{textTransform: 'none'}}>
            <ContactMailIcon />  /contact
          </Button> */}
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg" style={{ marginTop: 30 }}>
        <Routes>
          <Route exact path="/" element={HomePage()} />
          {/* <Route path="/projects" element={ProjectsPage} /> */}
          {/* <Route path="/blog" element={BlogPage} /> */}
          {/* <Route path="/contact" element={ContactPage} /> */}
        </Routes>
      </Container>
    </Router>
  );
}

export default App;
